import '@/initializers'
import Rails from '@rails/ujs'
// Entry point for the build script in your package.json
import { Turbo } from '@hotwired/turbo-rails'

// Load Stimulus controllers
import '@/controllers'

import '@/application'

// Disable Turbo Drive to keep as before
Turbo.session.drive = false

// Start UJS
window.Rails = Rails
if (!window._rails_loaded) Rails.start()

// Refresh the CSRF token on every page load to prevent CSRF false-positive
// detections.
document.addEventListener('turbo:load', () => Rails.refreshCSRFTokens())

// * Fix for turbo preserving scroll state when navigating to a new page
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual'
}
